//© 2020, Zio Health Ltd. All rights reserved
import React, { Component } from "react";
import Dropdown from 'react-dropdown';
import Loader from 'react-loader-spinner';
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
import * as math from 'mathjs'

import Refresh from '../../img/refresh.jpg';
import SettingsIcon from '../../img/settings.png';
import SettingsIconHighlighted from '../../img/settings_highlighted.png';
import Select from 'react-select';
import { animateScroll } from "react-scroll";
import { typeOf } from "mathjs";

//This Block to ensure there aren't multiple tabs connected to the same device
let connected_device_name = '';
window.addEventListener("unload", () => {
  removeLSConnectedDevice();
})
const removeLSConnectedDevice = () => {
  let connectedDevices = JSON.parse(window.localStorage.getItem('zio_connected_devices'));
  connectedDevices = connectedDevices.filter(element => element !== connected_device_name);
  window.localStorage.setItem('zio_connected_devices', JSON.stringify(connectedDevices));
}
const checkDuplicateConnections = () => {
  let connectedDevices = JSON.parse(window.localStorage.getItem('zio_connected_devices'));
  if (connectedDevices && connectedDevices.indexOf(connected_device_name) !== -1)
    alert('Multiple Connections to Device detected! Be sure to have only one Google Chrome tab connected to the ZiO Device before running a test otherwise data will be lost')
}
const localStoreConnectedDevice = () => {
  let connectedDevices = JSON.parse(window.localStorage.getItem('zio_connected_devices'));
  checkDuplicateConnections();
  if (connectedDevices)
    connectedDevices.push(connected_device_name);
  else 
    connectedDevices = [connected_device_name];
  window.localStorage.setItem('zio_connected_devices', JSON.stringify(connectedDevices));
}
let current_service = null;
let outputHeadersAsStrings = [
  "Filtered Concentration Test Result",
  "All_Av_Concentration",
  "Electrode_All",
  "Average_Concentration",
  "Frequency_All",
  "Electrode_Number",
   "Average_pA",
  "SD",
  "RD",
  "Curve",
  "Device",
  "Length",
  "Electrode",
  "Frequency Hz",
  "Start_Voltage mV",
  "Range",
  "Peak_Current pA",
  "Error_Code",
  "SWVs",
  "Temperature",
  "Concentration",
  "A","B","N","Equation","x"];
let outputHeadersAsObjects = [];
let currentDevice  = null;
let refreshGraph = false;
let packet_count_start = -2;
let packet_count = packet_count_start;
let meta_data = [];
let temperatureRanges = []; // [[min, max], [min,max], [min,max]]
let concentrationParameters = [{}, {}, {}]; //Three sets of parameters for each temperature range
let dataBuffer = [];
let unshiftBuffer = null;
let unshiftAmount = null;
let max_render = 20; //Max number of cures to render
let render_data = [];
let output_data = [];
let curve_count = 0;
let new_message = "";
let current_curve_data = {};
let all_curve_data = [];
let automation_configs = []
let test_count = 0;
let current_data_length = 0;
let current_curve_length = null;
let current_start_voltage = null;
let current_sweep_direction = null;
let test_state = "waiting";
let capturedMetaData = 0;
let metaDataLabels = [
  "Length",
  "Electrode",
  "Frequency Hz",
  "Start_Voltage mV",
  "Range",
  "Peak_Current pA",
  "Error_Code",
  "SWVs",
  "Temperature",
  "Sensor Ambient Temperature",
  "Ambient Temperature",
  "Ambient Humidity",
  "Chip Temperature",
  "Noise Level",
  "Sweep Direction",
];
let previousPeak = null; //For concentration equation
let previousError = false; //For concentration equation
let concentrationEquationOptions = [
  { value: 1, label: 'Linear'},
  { value: 2, label: 'Hyperbolic'},
  { value: 3, label: 'Exponential'}
];
let sweepDirectionOptions = [
  { value: 0, label: 'Low to High'},
  { value: 1, label: 'High to Low'},
];
let data_count = 0;
let last_written_data = {};
let db = null;

if (!('indexedDB' in window)) {
  console.log('This browser doesn\'t support IndexedDB');
}

class SettingsMenu extends Component {

  constructor(props) {
    console.log("Build Version 1.03");
    super(props);
    this.state = {
      firstRunOfAutomation: true,
      debug: false,
      show_error: false,
      error_message: "",
      preparing_save: false,
      save_ready: false,
      status_message: "",
      user_disconnected: false,
      battery_level: 100,
      loading_file: false,
      automated_test: false,
      characteristics_menu_open: false,
      loading: false,
      device_name: null,
      device_paired: null,
      current_service: null,
      menu_open: false,
      running_test: false,
      cart_detect: 0,
      frequency_NR: 180,
      frequency: 400,
      start_voltage: -300,
      range: 7,
      range_NR: 7,
      we_selected: 1,
      SWV: 3,
      selectedEquation: concentrationEquationOptions[0],
      A: 0,
      B: 0,
      N: 0,
      concentration_avg_filter: 0,
      selectedSweepDirection: sweepDirectionOptions[0],
    }
  }
  
  componentDidMount = () => {
    //setInterval( this.updateBattery, 20000);
  }

  flushMemory = () => {
    meta_data = [];
    dataBuffer = [];
    render_data = [];
    output_data = [];
    curve_count = 0;
    new_message = "";
    current_curve_data = {};
    all_curve_data = [];
    automation_configs = []
    test_count = 0;
    current_curve_length = null;
    current_start_voltage = null;
    test_state = "waiting";
    capturedMetaData = 0;
    let objectStore = db.transaction(["dataBuffer"], "readwrite").objectStore("dataBuffer");
    let req = objectStore.clear() 
    let objectStore2 = db.transaction(["renderData"], "readwrite").objectStore("renderData");
    req = objectStore.clear() 
    this.props.updateGraphMethod([], []);
    this.setState({status_message: ""});
  }
  
  exportedData = () => {
    console.log("Data Exported: ", dataBuffer);
    this.setState({show_error: false});
  }

  saveMetaData = () => {
    if (this.state.automated_test)
      current_curve_data["automated_row_number"] = automation_configs[0].Order;
    new_message = new_message + "\n \n"; 
    Object.keys(current_curve_data).map(el => {
      new_message = new_message + "\n " + el + ": " + current_curve_data[el];
    })
    current_sweep_direction = parseInt(current_curve_data["Sweep Direction"]);
    // If sweep direction is high to low, start voltage is - 500 
    current_start_voltage = current_sweep_direction === 1 ?
     parseInt(current_curve_data["Start_Voltage mV"]) - 500 : parseInt(current_curve_data["Start_Voltage mV"]);
    this.setState({status_message: new_message});
    setTimeout(() =>  {
      animateScroll.scrollToBottom({
        containerId: "status-message"
      });
    }, 500);
  }

  //This method is triggered every time a bluetooth notification is recieved on the device
  handleCharacteristicValueChanged = async (event) => {
    let dv = event.target.value;

    let incomingPackets = [
      dv.getInt32(0, true),
      dv.getInt32(4, true),
      dv.getInt32(8, true),
      dv.getInt32(12, true),
      dv.getInt32(16, true)
    ];

    if (this.state.debug)
      console.log("Raw data packet" , incomingPackets);

    while (incomingPackets.length > 0 ) {
      let currentDataVal = incomingPackets[0];

      if (currentDataVal === -1431655766) {
        test_state = "metadata";
        this.setState({loading: true});
      } else if (currentDataVal === -572662307) {
        test_state = "testdata";
        capturedMetaData = 0;
        current_data_length = 0;
        this.saveMetaData();
      } else if( currentDataVal === 1431655765) {
        test_state = "waiting";
        meta_data.push(current_curve_data);

        if (curve_count % 2 !== 0) {
          if (current_sweep_direction === 1)
            this.updateGraph(current_curve_data["Start_Voltage mV"] - 1, curve_count);
          else 
            this.updateGraph((current_data_length - Math.abs(current_curve_data["Start_Voltage mV"]) - 1), curve_count);
        }

        current_curve_data = {};
        curve_count ++;
        data_count = 0;

        test_count --;


      } else if(test_state === "metadata") {
        if (capturedMetaData >= metaDataLabels.length) {
          console.log("Extra meta data values found but there are not enough labels defined");
        } else  {
          if (capturedMetaData === 0) {
            current_curve_data["Curve"] = "y" +curve_count+ " ADC";
            current_curve_data['Device'] = this.state.device_name;
          }
          const currentLabel = metaDataLabels[capturedMetaData];
          if (
            currentLabel === "Temperature" ||
            currentLabel === "Sensor Ambient Temperature" ||
            currentLabel === "Temperature" ||
            currentLabel === "Ambient Temperature" ||
            currentLabel === "Ambient Humidity" ||
            currentLabel === "Chip Temperature"
          )
            current_curve_data[currentLabel] = currentDataVal / 100; // temp values need to be divided by 100
          else
            current_curve_data[currentLabel] = currentDataVal;
          //console.log(previousPeak, current_curve_data, current_curve_data[metaDataLabels[capturedMetaData]], metaDataLabels[capturedMetaData]);
          //Store freq peak so concentration can be calculated for freqNR
          if ((capturedMetaData + 1) == metaDataLabels.length) {
            current_curve_data['A'] = this.state.A;
            current_curve_data['B'] = this.state.B;
            current_curve_data['N'] = this.state.N;
            previousPeak = null;
            previousError = false;
            if (!previousPeak) {
              if (current_curve_data['Error_Code'] != 0)
                previousError = true;
              else
                previousPeak = current_curve_data['Peak_Current pA'];
            }
          }  
          capturedMetaData ++;
        }
      } else if(test_state === "testdata") {
        current_data_length ++;
        this.storeDataBuffer({x: current_start_voltage + data_count, ["y" +curve_count+ " ADC"]: currentDataVal}, curve_count);
        data_count ++;
      }

      incomingPackets.shift();
    }
  }

  /*  I think the curveCount is passed into this
      (as aposed to global variable: curve_count)
      function because we are limiting it to only
      the curves we have the data for.
  */
  updateGraph = (expected_length, curveCount) => {
    let curves = [];
    // This if ensure all data points are written to indexedDB before rendering to graph
    if (last_written_data[curveCount] >= expected_length) {
      if ((curveCount + 1) <= max_render) {
        for (let i = 0; i <= curveCount;i++) {
          curves.push(i); 
        }
        let objectStore = db.transaction(["dataBuffer"], "readwrite").objectStore("dataBuffer");
        let req2 = objectStore.getAll();
        req2.onerror = e => {console.log(e);};
        req2.onsuccess = e => {
          if (this.state.debug)
            console.log(e.target.result);
          this.props.updateGraphMethod(e.target.result, curves);
          this.setState({loading: false});
          if (this.state.automated_test) {
            console.log("Automated row order number: " + automation_configs[0].Order + " " + (test_count % 2 == 0 ? '' : "(NR)") );
            if (test_count == 0) { 
              //If both tests were run and were still doing automated testing go onto the next row;
              automation_configs.shift();
              this.run_automated_tests(automation_configs);
            } 
          }
        };
      } else {
        let firstCurve = (curveCount - max_render + 1);
        for (let i = firstCurve; i <= curveCount;i++) {
          curves.push(i); 
        }
        let objectStore = db.transaction(["renderData"], "readwrite").objectStore("renderData");
        let req2 = objectStore.getAll();
        req2.onerror = e => {console.log(e);};
        req2.onsuccess = e => {
          if (this.state.debug)
            console.log(e.target.result);
          this.props.updateGraphMethod(e.target.result, curves);
          this.setState({loading: false});
          if (this.state.automated_test) {
            console.log("Automated row order number: " + automation_configs[0].Order + " " + (test_count % 2 == 0 ? '' : "(NR)") );
            if (test_count == 0) { 
              //If both tests were run and were still doing automated testing go onto the next row;
              automation_configs.shift();
              this.run_automated_tests(automation_configs);
            } 
          }
        };
      }
    } else {
      setTimeout(e => {this.updateGraph(expected_length, curveCount)}, 1000);
    }
  }

  storeDataBuffer = (obj, curveCount) => {
    let objectStore = db.transaction(["dataBuffer"], "readwrite").objectStore("dataBuffer");
    let getReq = objectStore.get(data_count + current_start_voltage);
    getReq.onerror = async ( e ) => {
      console.log("error ");
      return;
    }

    getReq.onsuccess = async (e) => {
      let dataToAdd = obj
      if (e.target.result) {
        //Combine new and old
        dataToAdd = {...obj, ...e.target.result};
      } 
      let dataBufferTransaction = db.transaction(["dataBuffer"], "readwrite");
      let objectStore = dataBufferTransaction.objectStore("dataBuffer");
      let requestUpdate = objectStore.put(dataToAdd); 
      requestUpdate.addEventListener('error', (event) => {
        console.log('Request error:', requestUpdate.error);
      });
      requestUpdate.onerror = function(event) {
        console.log("error storing data in indexedDB");
        return;
      };
      requestUpdate.onsuccess = function(event) {
        if ((curveCount + 1) === max_render && obj.x == current_start_voltage ) {
          //console.log("maxRender reached", curveCount, max_render);
          let req2 = objectStore.getAll();
          req2.onerror = e => {console.log(e);};
          req2.onsuccess = e => {
            let renderDataStore = db.transaction(["renderData"], "readwrite").objectStore("renderData");
            e.target.result.forEach(value => {
                renderDataStore.put(value);
            })
          };
        } else if((curveCount + 1) > max_render) {
          //console.log("maxRender exceeded", curveCount, max_render);
          let renderDataStore = db.transaction(["renderData"], "readwrite").objectStore("renderData");
          //First curve to delete 
          let firstCurve = (curveCount - max_render - 1);
          if (obj.x == current_start_voltage && (curveCount % 2 == 0)) {
            //curve_count % 2 is to make sure this is only done once for each pair
            let req2 = renderDataStore.getAll();
            requestUpdate.addEventListener('error', (event) => {
              console.log('Request error:', requestUpdate.error);
            });
            req2.onerror = e => {console.log(e);};
            req2.onsuccess = e => {
              e.target.result.forEach(value => {
                  delete value["y" + firstCurve + " ADC"];
                  delete value["y" + (firstCurve+1) + " ADC"];
                  if(Object.keys(value).length <= 1)
                    renderDataStore.delete(value.x);
                  else 
                    renderDataStore.put(value);
              })
            };
          }
          delete dataToAdd["y" + firstCurve + " ADC"];
          delete dataToAdd["y" + (firstCurve+1) + " ADC"];
          let requestUpdate2 = renderDataStore.put(dataToAdd); 
          requestUpdate.onerror = function(event) {
            console.log("error storing data in indexedDB");
            return;
          };
        }
        //return;
      };
      dataBufferTransaction.oncomplete = () => {
        if (last_written_data[curveCount] && obj.x != last_written_data[curveCount] + 1)
          console.log("MISSED DATA AT CURVE", curveCount, obj.x, last_written_data[curveCount])
        last_written_data[curveCount] = obj.x;
      }
      dataBufferTransaction.onabort = () => {
        console.log("TRANSACTION ABORTED CURVE", curveCount, dataBufferTransaction.error);
      }

    }
  }

  pairDevice = async () => {
    let pairedDevice  = null;
    try {
      console.log("Trying to pair...");
      let current_service  = null;
      //Below is a request that uses a filter, so no irrelevant bluetooth devices are shown
      //navigator.bluetooth.requestDevice({filters: [{name: 'Potentiostat'}], optionalServices: ['16d30bc1-f148-49bd-b127-8042df63ded0']})
      let pairedDevice = await navigator.bluetooth.requestDevice({acceptAllDevices: true,optionalServices: ['16d30bc1-f148-49bd-b127-8042df63ded0', '0000180a-0000-1000-8000-00805f9b34fb']});
      let device = pairedDevice;
      connected_device_name = device.name;
      localStoreConnectedDevice();
      this.setState({loading: true});
      pairedDevice.addEventListener('gattserverdisconnected', this.onDisconnected);
      // if (this.state.debug)
        // console.log("pairedDevice", JSON.stringify(pairedDevice), pairedDevice, device);
      // Attempts to connect to remote GATT Server.
      let server = await device.gatt.connect();
      console.log("server", JSON.stringify(server));
      let service = await server.getPrimaryService('16d30bc1-f148-49bd-b127-8042df63ded0');
      let infoService = await server.getPrimaryService('0000180a-0000-1000-8000-00805f9b34fb');
      let enc = new TextDecoder("utf-8");
      let firmwareCharacteristic = await infoService.getCharacteristic('00002a26-0000-1000-8000-00805f9b34fb');
      let firmwareValue = await firmwareCharacteristic.readValue();
      let firmwareString = enc.decode(firmwareValue);
      console.log("service", JSON.stringify(service));
      let request = window.indexedDB.open(pairedDevice.id, 2);
      request.onerror = function(event) {
        console.log("Error loading IndexedDB, code:", event.target.errorCode);
      };
      request.onsuccess = function(event) {
        db = event.target.result;
        let objectStore = db.transaction(["dataBuffer"], "readwrite").objectStore("dataBuffer");
        objectStore.clear() 
        let req2 = objectStore.getAll() 
        req2.onerror = e => {console.log(e);};
        req2.onsuccess = e => {console.log("IndexedDB connected.");};

        let objectStore2 = db.transaction(["renderData"], "readwrite").objectStore("renderData");
        objectStore2.clear() 
      };
      request.onupgradeneeded = function(event) { 
        db = event.target.result;
        var objectStore = db.createObjectStore("dataBuffer", {keyPath: 'x'});
        var objectStore = db.createObjectStore("renderData", {keyPath: 'x'});
      };
      this.setState({loading: false, device_name: pairedDevice.name, device_paired: pairedDevice, current_service: service, firmware: firmwareString});
      this.updateBattery();
    } catch(error) { 
      console.error("Pairing failed", error);
      this.setState({loading: false, user_disconnected: true, device_name: null, device_paired: null, current_service: null});
      if (pairedDevice)
        pairedDevice.gatt.disconnect();
    };
  }

  disconnectDevice = () => {
    if (this.state.device_paired) {
      this.state.device_paired.gatt.disconnect(); 
      removeLSConnectedDevice();
      this.setState({user_disconnected: true, device_name: null, device_paired: null, current_service: null});
    } 
  }

  onDisconnected = (e) => {
    console.log('> Bluetooth Device disconnected');
    try {
      this.state.device_paired.gatt.disconnect(); 
    } catch(e) {
      console.error(e);
    }
    if(!this.state.user_disconnected) {
      removeLSConnectedDevice();
      this.setState({show_error: true, error_message: "Device Disconnected"});
      this.reconnectDevice();
    }
  }

  logTime = (text) => {
    console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
  }

  re_run_automated_tests = () => {
    if (this.state.automated_test) {
      let i;
      for(i = (meta_data.length - 1);i >= 0; i--) {
        if (meta_data[i]["Automated_Row_Number"] == automation_configs[0].Order) {
          curve_count --; 
        } else {
          break; 
        }
      }
      console.log("DONE", curve_count);
      this.run_automated_tests(automation_configs);
      return;
    }
  }

  reconnectDevice = () => {
    this.exponentialBackoff(15 /* max retries */, 2 /* seconds delay */,
      () => { //toTry()
        this.logTime('Reconnecting to Bluetooth Device... ');
        return this.state.device_paired.gatt.connect();
      },
      (server) => { //success()
        if (this.state.debug)
            console.log("server", server);
        server.getPrimaryService('16d30bc1-f148-49bd-b127-8042df63ded0').then(service => {
          this.setState({current_service: service, show_error: false});
          console.log('> Bluetooth Device connected. Reconnected');
          this.re_run_automated_tests();
        }).catch(error => { 
          console.log("Reconnect to Service", error);
          this.reconnectDevice(); //Try again
        });
      },
      () => { //fail()
        this.logTime('Failed to reconnect.');
      });
  }

  // This function keeps calling "toTry" until promise resolves or has
  // retried "max" number of times. First retry has a delay of "delay" seconds.
  // "success" is called upon success.
  exponentialBackoff = (max, delay, toTry, success, fail) => {
    toTry().then(result => success(result))
    .catch(e => {
      if (max === 0) {
        return fail();
      }
      console.error(e);
      this.logTime('Retrying in ' + delay + 's... (' + max + ' tries left)');
      setTimeout(() => {
        this.exponentialBackoff(--max, delay * 2, toTry, success, fail);
      }, delay * 1000);
    });
  }


  updateCharacteristics = async (run_test_after) => {
    
      this.setState({menu_open: false, loading: true});
      // Dataview needs to be made to make a big endian ArrayBuffer

      let buffer = null;
      let dv = null;
      let SWV_enabled = true;

      try {
        buffer = new ArrayBuffer(1);
        dv = new DataView(buffer);
        dv.setUint8(0, parseInt(this.state.SWV), false);
        let SWV_characteristic = await this.state.current_service.getCharacteristic('16d30bce-f148-49bd-b127-8042df63ded0');

        if (this.state.SWV < 0) {
          alert("SWV characteristic must be greater than 0");
          return;
        }
        SWV_characteristic.writeValue(dv.buffer);
      }
      catch(e) {
        SWV_enabled = false;
        console.log(e, ": error updating SWV");
      }

      // Write to BLE_UUID_ZIO_FREQ1_CHAR 
      buffer = new ArrayBuffer(2);
      dv = new DataView(buffer);
      dv.setUint16(0, parseInt(this.state.frequency), false);
      let freq1_characteristic = await this.state.current_service.getCharacteristic('16d30bc2-f148-49bd-b127-8042df63ded0');
      freq1_characteristic.writeValue(dv.buffer);

      // Write to BLE_UUID_ZIO_FREQNR_CHAR 
      buffer = new ArrayBuffer(2);
      dv = new DataView(buffer);
      dv.setUint16(0, parseInt(this.state.frequency_NR), false);
      let freqNR_characteristic = await this.state.current_service.getCharacteristic('16d30bc3-f148-49bd-b127-8042df63ded0');
      freqNR_characteristic.writeValue(dv.buffer);

      // Write to BLE_UUID_ZIO_START_VOLT_CHAR 
      buffer = new ArrayBuffer(2);
      dv = new DataView(buffer);
      dv.setInt16(0, parseInt(this.state.start_voltage), false);
      let startV_characteristic = await this.state.current_service.getCharacteristic('16d30bc4-f148-49bd-b127-8042df63ded0');
      startV_characteristic.writeValue(dv.buffer);

      // Write to BLE_UUID_ZIO_RANGE1_CHAR 
      buffer = new ArrayBuffer(1);
      dv = new DataView(buffer);
      dv.setUint8(0, parseInt(this.state.range), false);
      let range1_characteristic = await this.state.current_service.getCharacteristic('16d30bc6-f148-49bd-b127-8042df63ded0');
      range1_characteristic.writeValue(dv.buffer);

      // Write to BLE_UUID_ZIO_RANGENR_CHAR 
      buffer = new ArrayBuffer(1);
      dv = new DataView(buffer);
      dv.setUint8(0, parseInt(this.state.range_NR), false);
      let rangeNR_characteristic = await this.state.current_service.getCharacteristic('16d30bc7-f148-49bd-b127-8042df63ded0');
      rangeNR_characteristic.writeValue(dv.buffer);

      // Write to BLE_UUID_WE_SELECT
      buffer = new ArrayBuffer(4);
      dv = new DataView(buffer);
      dv.setUint32(0, parseInt(this.state.we_selected), false);
      let WE_characteristic = await this.state.current_service.getCharacteristic('16d30bcb-f148-49bd-b127-8042df63ded0');
      WE_characteristic.writeValue(dv.buffer);

      // Write to sweep direction characteristic
      buffer = new ArrayBuffer(1);
      dv = new DataView(buffer);
      console.log("trying to write sweepDirection: ", this.state.selectedSweepDirection);
      if (this.state.selectedSweepDirection)
        dv.setUint8(0, parseInt(this.state.selectedSweepDirection.value), false);
      let sweepDirection_characteristic= await this.state.current_service.getCharacteristic('16d30bd2-f148-49bd-b127-8042df63ded0');
      sweepDirection_characteristic.writeValue(dv.buffer);

      let message = "\n \n Characteristics updated: \n Frequency1: "+ this.state.frequency +
      "\n Frequency2: "+ this.state.frequency_NR +
      "\n Start Voltage: "+ this.state.start_voltage +
      "\n Range1: "+ this.state.range +
      "\n RangeNR: "+ this.state.range_NR +
      "\n WE Selected: "+ this.state.we_selected +
      "\n Sweep Direction: "+ this.state.selectedSweepDirection.label;

      if (SWV_enabled)
        message = message + "\n SWV: " + this.state.SWV;
      this.setState({status_message: this.state.status_message + message, loading: false});
      setTimeout(() =>  {
        animateScroll.scrollToBottom({
          containerId: "status-message"
        });
      }, 500);

      if (run_test_after)
        this.runTest();

  }

  runTest = () => {
    console.log("Attempting to run tests...");

    test_count = ((this.state.we_selected + "").split("1").length - 1) * 2;
    
    //Cartridge detect
    this.state.current_service.getCharacteristic('16d30bc5-f148-49bd-b127-8042df63ded0').then(characteristic => {
      characteristic.readValue().then(value => {
        //if there is no cartridge detected value.getInt8 should == 0
        if (value.getInt8() == 0) {
        //if (false) { // Debuging without cartridge
          console.log("No cartridge detected");
          alert("No cartridge detected");
          return;
        } else {
          packet_count = packet_count_start;
          //Listen to Data characteristic
          this.state.current_service.getCharacteristic('16d30bc9-f148-49bd-b127-8042df63ded0').then(characteristic => {
            characteristic.startNotifications();
            return characteristic;
          }).then(characteristic => {
            characteristic.addEventListener('characteristicvaluechanged', this.handleCharacteristicValueChanged);
          }).then(_ => {
            //Write 1 to CMD characteristic to start test
            this.state.current_service.getCharacteristic('16d30bc8-f148-49bd-b127-8042df63ded0').then(CMDCharacteristic => {
                console.log("running Tests");
                CMDCharacteristic.writeValue(Uint8Array.of(1));
            })
            .catch(error => { console.log(error); });
            this.setState({loading: true})
          })
          .catch(error => { console.log(error); });
        }
      }); 
    }).catch(error => { console.log("Cartridge-Detect Characteristic Read", error)});
  }

  toggleMenuOpen = () => {
    this.setState({
      menu_open: !this.state.menu_open,
      characteristics_menu_open: false,
      loading_file: false,
      save_ready: false
    })
  }

  open_characteristics_menu = () => {
    this.setState({characteristics_menu_open: true}) 
  }
  
  handleInputParameterChange = (e) => {

    if (this.state.debug) 
      console.log("target, name:", e.target.value, e.target.name);

    if (e.target.value == "on" || e.target.value == "off" ) {
      this.setState({[e.target.name]: !this.state[e.target.name]});
      return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  stop_automated_test = () => {
    this.setState({automated_test: false, menu_open: false});
    console.log("Stopping automated test (not running next row)..");
  }

  open_loading_file = () => {
    this.setState({loading_file: true});
  }

  exportedData = () => {
    outputHeadersAsStrings = [
      "Filtered Concentration Test Result",
      "All_Av_Concentration",
       "Electrode_All",
      "Average_Concentration",
      "Frequency_All",
      "Electrode_Number",
       "Average_pA",
      "SD",
      "RD",
      "Curve",
      "Device",
      "Length",
      "Electrode",
      "Frequency Hz",
      "Start_Voltage mV",
      "Range",
      "Peak_Current pA",
      "Error_Code",
      "SWVs",
      "Temperature","Concentration","A","B","N","Equation","x"];
    outputHeadersAsObjects = [] 

  }

  calculateTemperatureRangeIndex = () => {
    let sum = 0;
    for (const obj of meta_data) {
      sum += obj.Temperature;
    }
    const averageTemp = sum / meta_data.length / 100;
    console.log(averageTemp, "(averageTemp)");
    if (
      temperatureRanges[2] &&
      temperatureRanges[2].length > 1 &&
      parseFloat(temperatureRanges[2][0]) < averageTemp &&
      parseFloat(temperatureRanges[2][1]) > averageTemp
    ) {
      console.log("return temp 3");
      return 2;
    } else if (
      temperatureRanges[0] &&
      parseFloat(temperatureRanges[0][0]) < averageTemp &&
      parseFloat(temperatureRanges[0][1]) > averageTemp
    ) {
      console.log("return temp 2");
      return 0;
    } else {
      //Default return middle temp range
      console.log("return temp 1");
      return 1;
    }
  }
  
  prepare_save = async () => {
    console.log(meta_data, "meta_data");
    //Calculate Average, SD and RSD for each Frequency/Electrode pair
    this.setState({preparing_save: true});

    //Build frequency and electrode dictionary for each curve
    let freq_electrode_arrays = {}; // {"freq_elec": [x1,x2 .. xn]}
    meta_data.forEach((row) => {
      if (row["Peak_Current pA"] != 0) {
        if ((row["Frequency Hz"] + "_" + row.Electrode) in freq_electrode_arrays) {
          freq_electrode_arrays[row["Frequency Hz"] + "_" + row.Electrode].push(row["Peak_Current pA"]);
        } else {
          freq_electrode_arrays[row["Frequency Hz"] + "_" + row.Electrode] = [row["Peak_Current pA"]];
        }
      }
    });

    let avg_sd_rsd = []; 
    let pairs = Object.keys(freq_electrode_arrays);
    console.log(freq_electrode_arrays, "freq_electrode_arrays");
    let tempRatio = {};
    let ratios = {};
    let finalConcentrations = []
    console.log("before");
    console.log(pairs, "pairs");
    await pairs.forEach(async (currentPair) => {
      let avg = math.mean(freq_electrode_arrays[currentPair]) 
      let sd = math.std(freq_electrode_arrays[currentPair]) 
      let currentElectrode = currentPair.split("_")[1]
      let currentFrequency = currentPair.split("_")[0]
      avg_sd_rsd.push({
        "Frequency_All": currentFrequency,
        "Electrode_Number": currentElectrode,
        "Average_pA": avg,
        "SD" : sd,
        "RD": sd/avg
      })      
      // calculate the ratio freqNR/freq for each electrode
      currentElectrode = currentElectrode;
      if (tempRatio[currentElectrode]) {
        // always bigger frequency as nominator and smaller freq and denominator
        if (
          parseInt(tempRatio[currentElectrode].freq) >
          parseInt(currentPair.split("_")[0])
        )
          ratios[currentElectrode] = tempRatio[currentElectrode].val / avg;
        else
          ratios[currentElectrode] = avg / tempRatio[currentElectrode].val;
      } else {
        tempRatio[currentElectrode] = {
          val: avg,
          freq: currentPair.split("_")[0],
        };
      }
    })

    const formattedRatios = []
    for (let i of Object.keys(ratios)) {
      formattedRatios.push({
        "Electrode_All": i,
        "Average_Ratio": ratios[i]
      })
    }

    const tempRangeIndex = this.calculateTemperatureRangeIndex();
    const correctConcentrationValues = concentrationParameters[tempRangeIndex];
    console.log(correctConcentrationValues, "correctConcentrationValues");
    const result = await fetch(
      "https://9dc44g79m0.execute-api.us-east-1.amazonaws.com/dev/calculateConcentrationLab",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ratio_avg,
          correctConcentrationValues,
        }),
      }
    ).catch((error) => console.error("Error:", error));
    finalConcentrations.push(await result.json());

    const validRatios = Object.values(ratios).filter(
      (num) => num !== null && !isNaN(num) && num > 0 && num !== Infinity
    );
    let ratio_sum = validRatios.reduce((a, b) => a + b, 0);
    let ratio_avg = ratio_sum / validRatios.length;
    finalConcentrations[0]["Total_Average_Ratio"] = ratio_avg;

    console.log(finalConcentrations, "finalConcentrations");

    let electrode_avg = []
    // let electrodes = Object.keys(electrode_concentration_data);
    let all_concentration_avg_sum = [];
    // electrodes.forEach((currentElectrode) => {
    //   let avg = math.mean(electrode_concentration_data[currentElectrode]) 
    //   all_concentration_avg_sum.push(avg);
    //   electrode_avg.push({
    //     "Electrode_All": currentElectrode,
    //     [ "Average_Concentration"]: avg,
    //   })      
    // })  

    // let all_concentration_avg_sum_avg = 0;
    // if (all_concentration_avg_sum.length > 0)
    //   all_concentration_avg_sum_avg = math.mean(all_concentration_avg_sum);
      
    // let filtered_concentration_avg = 0;
    // if (all_concentration_avg_sum_avg > this.state.concentration_avg_filter) {
    //   filtered_concentration_avg = all_concentration_avg_sum_avg; 
    // }
    // let concentration_averages = [{
    //   "Filtered Concentration Test Result": filtered_concentration_avg,
    //   "All_Av_Concentration": all_concentration_avg_sum_avg
    // }]


    for (let i = 0; i <= curve_count -1;i++) {
      let headerString = "y"+i+" ADC";
      outputHeadersAsStrings.push(headerString);
    } 
    outputHeadersAsStrings.forEach(header => {
      outputHeadersAsObjects.push({"label": header, "key": header});
    }) ;
    let objectStore = db.transaction(["dataBuffer"], "readwrite").objectStore("dataBuffer");
    let req2 = objectStore.getAll();

    req2.onerror = e => {console.log(e);};
    req2.onsuccess = e => {
      output_data = meta_data.concat(e.target.result);
      output_data = avg_sd_rsd.concat(output_data);
      output_data = formattedRatios.concat(output_data);
      output_data = finalConcentrations.concat(output_data);
      this.setState({save_ready: true, preparing_save: false});
    };

  }

  open_loading_automated_test = () => {
    this.flushMemory();
    this.setState({loading_file: true});
  }

  //send the expected output row count it to the relative characteristic (Progress Bar)
  updateDeviceProgressBar = async (value) => {
    try {
      console.log(value, "progressBarVal");
      const buffer = new ArrayBuffer(1);
      const dv = new DataView(buffer);
      dv.setUint8(0, value, false);
      let progressBarCharacteristic = await this.state.current_service.getCharacteristic(
        "16d30bcd-f148-49bd-b127-8042df63ded0"
      );
      progressBarCharacteristic.writeValue(dv.buffer);
    } catch (e) {
      console.log("Error updating progress bar", e);
    }
  };

  //Calculates expected output row count for a given method file
  calculateOutputRowNumber = (data) => {
    let outputRowSum = 0;
    if (data && data.length > 1) {
      const rAHeaders = data[0];
      const weSelectIndex = rAHeaders.indexOf("WE Select");
      for (const row of data.slice(1)) {
        // Turn we select/electrode into test count
        // 10000 (means electrode 5) & 1010000 (means electrode 5 and 7)
        if (row.length >= weSelectIndex)
          outputRowSum += (row[weSelectIndex].split("1").length - 1) * 2;
      }
    }
    return outputRowSum;
  };
  
  //Extracts Parameters used for contentration equation from sample method file
  extractConcentrationParameters = (data) => {
    let concentrationParameterNames = [
      "A",
      "B",
      "N",
      "K0",
      "S0",
      "Smax",
      "eN",
    ];
    let headers = data[0];
    let firstRow, secondRow, thirdRow;
    const temperatureRangeIndex = headers.indexOf("Temperature Range");
    firstRow = data[1];
    secondRow = data[2];
    thirdRow = data[3];

    if (temperatureRangeIndex !== -1) {
      temperatureRanges = [
        [firstRow[temperatureRangeIndex].split("<T")],
        [secondRow[temperatureRangeIndex].split("<T")],
        [thirdRow[temperatureRangeIndex].split("<T")],
      ];
    }
    console.log(temperatureRanges, "temperatureRanges");

    concentrationParameterNames.forEach((header) => {
      let index = headers.indexOf(header);
      if (index !== -1) {
        concentrationParameters[0][header] = parseFloat(firstRow[index]);
        concentrationParameters[1][header] = parseFloat(secondRow[index]);
        concentrationParameters[2][header] = parseFloat(thirdRow[index]);
      }
    });

    console.log(
      JSON.stringify(concentrationParameters),
      "concentrationParameters"
    );
  };

  calculateTemperatureRangeIndex = () => {
    let sum = 0;
    for (const obj of meta_data) {
      sum += obj.Temperature;
    }
    const averageTemp = sum / meta_data.length / 100;
    console.log(averageTemp, "(averageTemp)");
    if (
      temperatureRanges[2] &&
      temperatureRanges[2].length > 1 &&
      parseFloat(temperatureRanges[2][0]) < averageTemp &&
      parseFloat(temperatureRanges[2][1]) > averageTemp
    ) {
      console.log("return temp 3");
      return 2;
    } else if (
      temperatureRanges[0] &&
      parseFloat(temperatureRanges[0][0]) < averageTemp &&
      parseFloat(temperatureRanges[0][1]) > averageTemp
    ) {
      console.log("return temp 2");
      return 0;
    } else {
      //Default return middle temp range
      console.log("return temp 1");
      return 1;
    }
  };

  handle_load_file = data => {
    this.updateDeviceProgressBar(this.calculateOutputRowNumber(data));
    this.extractConcentrationParameters(data);
    this.setState({menu_open: false, loading: true, loading_file: false, automated_test: true});
    let headers = data[0];
    let tempData = [];
    for(let i = 1; i < data.length;i++) {
      let tempRow = {}
      headers.forEach((val,index) => {
        tempRow[val] = data[i][index];
      });
      tempData.push(tempRow);
    }
    let pre_curve_count = headers.length - 1;
    let curves = [];
    for (let i = 0; i <= pre_curve_count;i++) {
      curves.push(i); 
    }

    if (this.state.automated_test) {
      tempData.sort(function (a, b) {
        return a.Order - b.Order;
      });
      automation_configs = tempData;
      this.run_automated_tests(tempData);
    } else {
      this.props.updateGraphMethod(tempData, curves);
      this.setState({loading: false, dataBuffer: tempData});
    }
  }

  run_automated_tests = async test_configs => {
    if (test_configs.length == 0) {
      await this.prepare_save();
      this.setState({show_error: true, automated_test: false, error_message: "Finished Test", firstRunOfAutomation: true});
    } else {
      let row = test_configs[0];
      if ( typeof row.Freq === "undefined" || typeof row.FreqNR === "undefined" || typeof row["Start Volt"] === "undefined" || typeof row.Range === "undefined" || typeof row.RangeNR === "undefined" || typeof row["WE Select"] === "undefined" || row.Freq == "" || row.FreqNR == "" || row["Start Volt"] == "" || row.Range == "" || row.RangeNR == "" || row["WE Select"] == "") {
        automation_configs.shift();
        this.run_automated_tests(automation_configs);
      } else {
        if (this.state.debug)
          console.log(row);

        let swv = 3; 
        if (typeof row["SWVs"]  === "undefined" || row["SWVs"] == "") {
          try {
            let SWV_characteristic = await this.state.current_service.getCharacteristic('16d30bce-f148-49bd-b127-8042df63ded0');
            let val = await SWV_characteristic.readValue();
            swv = val.getUint8();
          }
          catch(e) {
            console.log(e, ": failed capturing SWV");
            alert("Failed to capture SWV");
            swv = "";
          }
        } else {
          swv = parseInt(row["SWVs"]);
        }

        let EquationType = 1; 
        if (typeof row["Equation"]  !== "undefined" && row["Equation"] != "") {
          switch (row["Equation"].toLowerCase()) {
            case 'equation 1':
            case 'linear':
            case '1':
              EquationType = 1;
              break;
            case 'equation 2':
            case 'hyperbolic':
            case '2':
              EquationType = 2;
              break;
            case 'equation 3':
            case 'exponential':
            case '3':
              EquationType = 3;
              break;
          }
        } else if(this.state.firstRunOfAutomation) {
            alert("Failed to capture eqatuation type in automated test specifications. Test will still run with default values.");
        }

        let A = 0; 
        if (typeof row["A"]  !== "undefined" && row["A"] != "") {
          A = parseFloat(row["A"]);
        } else if(this.state.firstRunOfAutomation) {
            alert("Failed to capture A value in automated test specifications. Test will still run with default values.");
        }

        let B = 0; 
        if (typeof row["B"]  !== "undefined" && row["B"] != "") {
          B = parseFloat(row["B"]);
        } else if(this.state.firstRunOfAutomation) {
            alert("Failed to capture B value in automated test specifications. Test will still run with default values.");
        }

        let N = 0; 
        if (typeof row["N"]  !== "undefined" && row["N"] != "") {
          N = parseFloat(row["N"]);
        } else if(this.state.firstRunOfAutomation) {
            alert("Failed to capture N value");
        }

        let sweepDirection = 0;
        if (typeof row["Sweep Direction"]  !== "undefined" && row["Sweep Direction"] != "") {
          sweepDirection = parseInt(row["Sweep Direction"]);
        } else if(this.state.firstRunOfAutomation) {
            alert("Failed to capture Sweep Direction value in automated test specifications. Test will still run with default values.");
        }

        this.setState({
          firstRunOfAutomation: false,
          frequency: row.Freq, 
          frequency_NR: row.FreqNR, 
          start_voltage: parseFloat(row["Start Volt"]), 
          range: row.Range, 
          range_NR: row.RangeNR, 
          SWV: swv, 
          we_selected: parseInt(row["WE Select"]),
          selectedEquation: concentrationEquationOptions[EquationType - 1],
          A: A,
          B: B,
          N: N,
          selectedSweepDirection: sweepDirectionOptions[sweepDirection]});
        this.updateCharacteristics(true);
      }
    }
    
  }
  
  updateBattery = () => {
    if (this.state.current_service) {
      this.state.current_service.getCharacteristic('16d30bca-f148-49bd-b127-8042df63ded0').then(characteristic => {
        characteristic.readValue().then(value => {
          this.setState({battery_level: value.getInt8()});
        }); 
      }).catch(error => { console.log("Battery Characteristic Read", error)});
    }
  }

  changeEquation = selectedEquation => {
    this.setState({ selectedEquation });
  };

  changeSweepDirection = selectedSweepDirection => {
    this.setState({ selectedSweepDirection });
  };

  render() {
    return (
      <div style={ {zIndex: 1, position: 'absolute', width: '20vw', height: '100vh', right: 0} }>

        {this.state.show_error && 
          <div className="popup">
            <div style={{fontSize: 26, textAlign: 'center', width: '100%'}}> 
              {this.state.error_message}
            </div>

            {this.state.error_message == "Device Disconnected" && 
              <div className="button start-test" style={{height: 26}} onClick={this.reconnectDevice}> 
                Reconnect
              </div>
            }

            {this.state.error_message == "Finished Test" && 
              <CSVLink
                onClick={this.exportedData}
                data={output_data}
                style={{textDecoration: 'none', height: 30}}
                filename={ this.state.device_name + "_" + [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('-') + "_" + [new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()].join('-') + ".csv" }
                className="button start-test"
                // headers={outputHeadersAsObjects}
              > 
                Save 
              </CSVLink>
            }
          </div>
        }
        {this.state.menu_open ? 
        <div>
            <img  onClick={this.toggleMenuOpen} src={require('../../img/close.png')} alt="Settings" style={ {zIndex: 2, top: 9, right: '6vw', width: 40, cursor: 'pointer', position: 'absolute'} }/>
            {this.state.characteristics_menu_open 
            ? 
              <div className="settings-popup">

                <label className="option">
                  Frequency 1
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)} value={this.state.frequency} name="frequency" type="number" className="input-text small"/>
                    Hz
                  </div>
                </label>

                <label className="option">
                  Frequency 2
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)} value={this.state.frequency_NR} name="frequency_NR" type="number" className="input-text small"/>
                    Hz
                  </div>
                </label>


                <label className="option">
                  Start Voltage
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.start_voltage}  name="start_voltage" type="number" className="input-text small"/>
                    mV
                  </div>
                </label>


                <label className="option">
                  Range 1
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.range} name="range" type="number" className="input-text small"/>
                  </div>
                </label>


                <label className="option">
                  Range 2
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.range_NR} name="range_NR" type="number" className="input-text small"/>
                  </div>
                </label>

                <label className="option">
                  WE Selected
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.we_selected} name="we_selected" type="number" className="input-text small"/>
                  </div>
                </label>

                <label className="option">
                  SWVs
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)} value={this.state.SWV} name="SWV" type="number" className="input-text small"/>
                  </div>
                </label>

                <label className="option">
                  Equation
                  <div>
                    <Select
                      value={this.state.selectedEquation}
                      onChange={this.changeEquation}
                      options={concentrationEquationOptions}
                    />
                  </div>
                </label>

                <label className="option">
                  A
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.A} name="A" type="number" className="input-text small"/>
                  </div>
                </label>


                <label className="option">
                  B
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.B} name="B" type="number" className="input-text small"/>
                  </div>
                </label>


                <label className="option">
                  N
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.N} name="N" type="number" className="input-text small"/>
                  </div>
                </label>

                <label className="option">
                  Practical Limit of Detection
                  <div>
                    <input onChange={e => this.handleInputParameterChange(e)}  value={this.state.concentration_avg_filter} name="concentration_avg_filter" type="number" className="input-text small"/>
                  </div>
                </label>

                <label className="option">
                  Sweep Direction
                  <div>
                    <Select
                      value={this.state.selectedSweepDirection}
                      onChange={this.changeSweepDirection}
                      options={sweepDirectionOptions}
                    />
                  </div>
                </label>

                <div className="flex">
                  <div className="button start-test" onClick={() => {this.updateCharacteristics(false)}}> Update </div>
                </div>
              </div>
           :
             <div className="settings-popup">
             {this.state.loading_file 
             ?
              <CSVReader cssClass="load-csv" label="Select csv file to load" onFileLoaded={this.handle_load_file}>
              </CSVReader>
             :
             <div>

                {this.state.loading &&
                  <div className="flex">
                    <div className="button start-test" onClick={this.stop_automated_test}> 
                      Stop Automated Test
                    </div>
                  </div>
                }

                <div className="flex">
                  <div className="button start-test" onClick={this.disconnectDevice}> 
                    Disconnect Device
                  </div>
                </div>

               {curve_count > 0 &&
                 <div className="flex">

                    {this.state.save_ready ? 
                        <CSVLink 
                            onClick={this.exportedData}
                            data={output_data}
                            style={{textDecoration: 'none'}}
                            className="button" 
                            headers={outputHeadersAsObjects}
                            filename={ this.state.device_name + "_" + [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('-') + "_" + [new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()].join('-') + ".csv" } 
                        > 
                          Download Save Data
                        </CSVLink>
                      :
                        <div className="flex">
                          {this.state.preparing_save ? 
                            <div style={{padding: "15 0"}}>
                              Preparing...
                            </div>
                          :
                            <div className="button" onClick={this.prepare_save}> 
                              Prepare Save Data
                            </div>
                          }
                        </div>
                    }
                  </div>
                }
                
                {!this.state.loading &&
                  <div className="flex">
                    <div className="button start-test" onClick={this.open_loading_file}> 
                      Load Data
                    </div>
                  </div>
                }
                {!this.state.loading &&
                  <div className="flex">
                    <div className="button start-test" onClick={this.open_loading_automated_test}> 
                      Load Automated Test
                    </div>
                  </div>
                }

                {!this.state.loading &&
                  <div className="flex">
                    <div className="button start-test" onClick={this.open_characteristics_menu}> 
                      Test Parameters
                    </div>
                  </div>
                }

                <div className="flex">
                  <div className="button start-test" onClick={this.pairDevice}> 
                    Pair Device
                  </div>
                </div>

             </div>
             }
             </div>
           }
        </div>
        :
        //End is menu open? 
        <div >
          {
            this.state.device_paired == null 
            ? 
            <div>
              {this.state.loading ?
                <div style={{marginTop: 20, marginLeft: -27}}>
                  <Loader type="TailSpin" color="#7dbcd2" height={68} width={68}/>
                  Pairing...
                </div>
              :
                <div className="flex" style={ {position: 'absolute', height: '100vh', right: 50} }>
                  <div className="pair-device-button" onClick={this.pairDevice} >
                    Pair Device
                  </div>
                  {dataBuffer.length > 0 ?
                    <div className="flex">
                      {this.state.save_ready ? 
                          <CSVLink 
                            onClick={this.exportedData} 
                            data={output_data} 
                            style={{textDecoration: 'none'}} 
                            headers={outputHeadersAsObjects}
                            filename={ this.state.device_name + "_" + [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('-') + "_" + [new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()].join('-') + ".csv" } 
                            className="button"
                          > 
                            Download Save Data
                          </CSVLink>
                        :
                          <div className="flex">
                            {this.state.preparing_save ? 
                              <div style={{padding: "15 0"}}>
                                Preparing...
                              </div>
                            :
                              <div className="button" onClick={this.prepare_save}> 
                                Prepare Save Data
                              </div>
                            }
                          </div>
                      }
                    </div>
                  :
                    ""  
                  }
                </div>
              }
            </div>
            :
              <div>
              {
                this.state.loading
              ?
                <div>
                  <br></br><br></br>
                  <div style={{marginTop: -20, marginLeft: -27}}>
                    <Loader type="TailSpin" color="#7dbcd2" height={68} width={68}/>
                  </div>
                  <img  onClick={this.toggleMenuOpen} src={require('../../img/settings.png')} alt="Settings" 
                    onMouseOver={e => (e.currentTarget.src = require('../../img/settings_highlighted.png'))}
                    onMouseOut={e => (e.currentTarget.src = require('../../img/settings.png'))}
                    style={ {cursor: 'pointer', top: 10, right: '6vw', position: 'absolute'} }/>
                  <br></br><br></br>


                  
                  <div id="status-message" className="status-message">
                    {this.state.status_message}
                  </div>
                </div>
              :
               <div>
                <img  onClick={this.runTest} src={require('../../img/play_button.png')} alt="Settings"
                  onMouseOver={e => (e.currentTarget.src = require('../../img/play_button_highlighted.png'))}
                  onMouseOut={e => (e.currentTarget.src = require('../../img/play_button.png'))}
                style={ {cursor: 'pointer',top: 10, right: 'calc(6vw + 80px)', position: 'absolute'} }/>

                <img  onClick={this.toggleMenuOpen} src={require('../../img/settings.png')} alt="Settings" 
                  onMouseOver={e => (e.currentTarget.src = require('../../img/settings_highlighted.png'))}
                  onMouseOut={e => (e.currentTarget.src = require('../../img/settings.png'))}
                  style={ {cursor: 'pointer', top: 10, right: '6vw', position: 'absolute'} }/>


                                  <div className="device-message">
                    Connected to: {this.state.device_name} (v{this.state.firmware})
                  </div>

                  <div style={{marginTop: 10}}>
                  log output
                    <input
                      name="debug"
                      type="checkbox"
                      checked={this.state.debug}
                      onChange={e => this.handleInputParameterChange(e)} />
                  </div>
                  <div onClick={this.updateBattery} className="no-highlight device-message" style={{display: 'flex', alignItems: 'center', marginTop: 0}}>
                    Battery: {this.state.battery_level}%
                    <img src={Refresh} style={{width: 30, marginLeft: 5}} alt="Update Battery"/>
                  </div>

                  {typeof this.state.publicFilteredConcentration === 'number' &&
                    <h1> Filtered Concentration Average: {this.state.publicFilteredConcentration} </h1>
                  }

                  <div id="status-message" className="status-message">
                    {this.state.status_message}
                  </div>
               </div>

              }
             </div>
            }
        </div>
        }

      </div>
    )
  }
}

export default SettingsMenu;
//© 2020, Zio Health Ltd. All rights reserved
