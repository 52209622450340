//© 2020, Zio Health Ltd. All rights reserved
import React, { Component } from 'react';
import './App.scss';
import 'react-dropdown/style.css'
import SettingsMenu from './components/SettingsMenu';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

let colors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000']

let color_index = 0;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      curves: []
    }
  }

  updateGraph = (data, curves) => {
    this.setState({graphData: data,curves: curves});
    //console.log("Graph update data:", this.state.graphData);
  }

  render() {
    return (
    <div className="master-wrapper">
      <SettingsMenu updateGraphMethod={this.updateGraph}/>
      <div className="main-view">
        <div className="middle-view" style={{height: '95vh', width: '80%'}}>
          <ResponsiveContainer width='95%' height='100%'>
            <LineChart data={this.state.graphData}
                  margin={{top: 5, right: 30, left: 20, bottom: 5}}>
             <XAxis interval={19} dataKey="x"/>
             <YAxis/>
             <CartesianGrid strokeDasharray="3 3"/>
             <Tooltip/>
             <Legend />
              {
                this.state.curves.map((val,i) => {
                  if (val > colors.length) {
                    color_index = val - (Math.floor(val / colors.length)*colors.length);
                  } else {
                    color_index = val 
                  }
                  return (<Line dataKey={`y${val} ADC`} stroke={colors[color_index]} key={i} />)
                })
              }
            </LineChart>
          </ResponsiveContainer>
        </div>
        <p style={{textAlign: 'center'}} >
          If you have any problems, <a href="https://docs.google.com/forms/d/e/1FAIpQLSdo2MIEHSg9ZsYYtN0h7M2preLRf5cg_PhW9mownfdtNeCjAw/viewform?usp=sf_link" target="_blank"> click here</a> to report to Zio Health.
        </p>
        <p style={{textAlign: 'center'}} >
          © 2020, Zio Health Ltd. All rights reserved
        </p>
      </div>

    </div>
    );
  }
}

export default App;
//© 2020, Zio Health Ltd. All rights reserved
